import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FullPageLoader } from '../../components/loader';
import UnAuthenticateRoute from './UnAuthenticatedRoute';
import AuthenticateRoute from './AuthenticateRoute';
import RedirectIfAuthenticatedRoute from './RedirectIfAuthenticatedRoute';
import { RouteKeys } from './route-keys';
import { AppSettingsStateViewModel } from 'alg-ecom-frontend-core';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

const Home = lazy(() => import('../home'));
const NotFound = lazy(() => import('../notfound'));
const TrimLevel = lazy(() => import('../trim-level'));
const EditionVarient = lazy(() => import('../edition-variant'));
const BuildVehicle = lazy(() => import('../build-vehicle'));
const Landing = lazy(() => import('../landing'));
const PaymentSelection = lazy(() => import('../payment/PayNow'));
const PaymentSuccess = lazy(() => import('../payment/PaymentSuccess'));
const AuthPage = lazy(() => import('../auth/AuthPage'));
const MyAccount = lazy(() => import('../dashboard'));
const ProductDetail = lazy(() => import('../product-detail/ProductDetail'));
const NewsListing = lazy(() => import('../news/NewsListing'));
const NewsDetail = lazy(() => import('../news/NewsDetail'));
const Contact = lazy(() => import('../cms/contact'));
const Owners = lazy(() => import('../cms/owners'));
const Offers = lazy(() => import('../cms/offers'));
const Insurance = lazy(() => import('../cms/insurance'));
const Finance = lazy(() => import('../cms/finance'));
const FinanceOpt = lazy(() => import('../cms/financeOpt'));
const VehicleProtection = lazy(() => import('../cms/vehicleProtection'));
const AboutUs = lazy(() => import('../cms/about'));
const PrivacyPolicy = lazy(() => import('../cms/privacypolicy'));
const Terms = lazy(() => import('../cms/terms'));
const Disclaimer = lazy(() => import('../cms/disclaimer'));
const EnquirySuccess = lazy(
  () => import('../product-detail/enquiry/EnquirySuccess')
);
const SubRoutes = lazy(() => import('./SubRoutes'));

interface CustomProps {
  appSettingsState: AppSettingsStateViewModel;
}
const Routes: React.FunctionComponent<CustomProps> = (props) => {
  return (
    <Suspense fallback={<FullPageLoader text={'Loading...'} />}>
      <Switch>
        <Route path={'/:lang'} component={SubRoutes} />
        <Redirect
          to={{
            pathname: `${
              props.appSettingsState?.htmlDirection === 'ltr' ? 'en' : 'ar'
            }${RouteKeys.Home}`,
          }}
          from={RouteKeys.Home}
        />

        <Route exact path={RouteKeys.NotFound} component={NotFound} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};
const mapStateToProps = (state: any) => ({
  appSettingsState: state.appSettingsState,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  // redirectSave: (to: string) => {
  //   dispatch(authUserActions.redirectSave(to));
  // },
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
