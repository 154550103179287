export enum RouteKeys {
  Home = '/',
  TrimLEvel = '/trim-level/:year/:modelCode/',
  EditionVariant = '/edition/modelyear/:year/model/:modelCode/trimcode/:trim/',
  BuildVehicle = '/build-vehicle/:id',
  PayNow = '/pay-now/',
  PaymentCallback = '/payment-callback/',
  Auth = '/auth/',
  MyAccount = '/my-account/',
  NotFound = '/page-not-found',
  Build = '/build',
  ProductDetail = '/detail/:model',
  News = '/news',
  NewsDetail = '/news/detail/:slug',
  Contact = '/contactus',
  Owners = '/owners',
  Offers = '/offers',
  Insurance = '/insurance',
  Finance = '/finance',
  FinanceOpt = '/financeOpt',
  VehicleProtection = '/vehicle-protection',
  AboutUs = '/about-us',
  PrivacyPolicy = '/privacy-policy',
  Terms = '/terms',
  Disclaimer = '/disclaimer',
  EnquirySuccess = '/enquiry',
  TrimLevelYear = '/trim-level-year/:modelId',
  ForgotPassword = '/auth/forgot-password',
  PaymentLink = '/paymentrequest',
  PaymentLinkSuccess = '/paymentrequest-success',
}
