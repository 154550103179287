const isDraftMode = process.env.REACT_APP_IS_DRAFT_MODE;
enum status {
  draft = 'draft',
  published = 'published',
}

const metaDetails = `{
  title
  description
  keyword
  ogTitle
}`;

const statusCondition = `
where:{status:"${isDraftMode === 'Yes' ? status.draft : status.published}"}
`;
export const getVehicleDetailQuery = (language: string, slug: string) => {
  return `query Vehicles {
        vehicles(locale: "${language}",where:{slug:"${slug}"}) {
        metaDetails ${metaDetails}
        id
          slug
          title
          megaMenuMainImage {
            url
          }
          megaMenuThumb {
            url
          }
          locale
          localizations ${localizations}
          banner ${bannerItem}
          specs {
            label,
            value
         }
          highlights{
              id
              title
              subtitle
              items${imageWithDescription}
          }
          design{
              id,
              title
              items${imageWithDescription}

          }
          detailItems{
            title
            subtitle
            items${imageWithDescription}
          }
        
          gallery${image}
        }
      }
    `;
};

export const getAllVehiclesQuery = () => {
  return `query Vehicles {
    vehicles(locale: "all",${statusCondition}) {
      metaDetails ${metaDetails}
      id
      slug
      priority
      title
      ecomModelCode
      price
      megaMenuMainImage {
        url
      }
      megaMenuThumb {
        url
      }
      locale
      localizations ${localizations}
      banner ${bannerItem}
      specs {
        label,
        value
     }
      highlights{
          id
          title
          subtitle
          items${imageWithDescription}
      }
      design{
          id,
          title
          items${imageWithDescription}

      }
      detailItems{
        title
        subtitle
        items${imageWithDescription}
      }
    
      gallery${image}
    }
   
  }
    `;
};

export const getServiceScreenQuery = (language: string) => {
  return `query Ownerships {
    ownerships(locale: "${language}",${statusCondition}) {
      metaDetails ${metaDetails}
      banner ${bannerItem}
        serviceItems {
          title
          description
          image {
            url
          }
        }
    }
}`;
};

export const getContactUsScreenQuery = (language: string) => {
  return `query Contactuses {
    contactuses(locale: "${language}",${statusCondition}) {
      title
      banner ${bannerItem}
      contacts ${contact}
      departmentContacts {
        title
        openingDetails
        phone
        email
      }
      metaDetails ${metaDetails}
    }
}`;
};

export const getAboutUSScreenQuery = (language: string) => {
  return `query Aboutuses {
    aboutuses(locale: "${language}",${statusCondition}) {
      metaDetails ${metaDetails}
      banner ${bannerItem}
        welcome{
          title
          description
        }
        items {
          title
          description
          image {
            url
          }
        }
    }
}`;
};

export const getOfferScreenQuery = (language: string) => {
  return `query Offers {
    offers(locale: "${language}",${statusCondition}) {
      metaDetails ${metaDetails}
      banner ${bannerItem}
       
        landingScreen {
          midSlider ${midSlider}
        }
        offerItem {
          title
          details
        }
    }
}`;
};

export const getFooterQuery = (language: string) => {
  return `query Footers {
    footers(locale: "${language}",${statusCondition}) {
        title
        menuItems {
          title
           items {
            label
            relativeUrl
            absoluteUrl
            action
          }
        }
        socialMedia{
          icon
          url
          title
        }
        
    }
}`;
};

export const getDisclaimerQuery = (language: string) => {
  return `query Disclaimers {
    disclaimers(locale: "${language}") {
        content
        metaDetails ${metaDetails}
      }
}`;
};

export const getTermsQuery = (language: string) => {
  return `query Terms {
    terms(locale: "${language}",${statusCondition}) {
        content
        metaDetails ${metaDetails}
      }
}`;
};

export const getPrivacyQuery = (language: string) => {
  return `query Privacies {
    privacies(locale: "${language}",${statusCondition}) {
        content
        metaDetails ${metaDetails}
      }
}`;
};

export const getNewsQuery = (language: string, limit?: number) => {
  return `query NewsItems {
    newsItems(locale: "${language}",sort:"date:desc"${
    limit != null ? ',limit :' + limit : ''
  },${statusCondition}) {
      title
      shortDescription
      priority
      date
      slug

      image {
          url
        }
      locale
      localizations {
        locale
        id
        slug
      }
    }
}`;
};

export const getNewsDetailsQuery = (language: string, slug: string) => {
  return `query NewsItems {
    newsItems(locale: "${language}",where:{slug:"${slug}"}) {
      title
      shortDescription
      description
      date
      slug

      image {
          url
        }
      locale
      localizations {
        locale
        id
        slug
      }
      metaDetails ${metaDetails}
    }
}`;
};

export const getLandingScreenQuery = (language: string) => {
  return `query LandingScreens {
    landingScreens(locale: "${language}",${statusCondition})  {
        metaDetails ${metaDetails}
        topSlider ${bannerItem}
      welcomeText {
        title
        description
      }
      midSlider ${midSlider}
      shoppingTool {
        title
        description
        items {
          link
          title
          action
          description
          image {
            url
          }
        }
      }
      inTouch {
        title
        items {
          position
          url
          iconClass
          image {
            url
          }
        }
      }
    }
  }
`;
};

const imageWithDescription = `{
    id,
    title,
    description
    image{
        url
    }
}`;

const localizations = `{
    locale
    id
    slug
  }`;

const image = `{
      name
      url
  }`;
const button = `{
    text
      relativeUrl
      absoluteUrl
      vehicle {
        id
        title
        slug
      }
  }`;

const midSlider = `{
    title
    description
    image {
      url
    }
    vehicle {
      id
      title
      slug
      price
      offerPrice
      ecommerceLink
    }
    button ${button}
  }`;
const contact = `{
    title
    address
    phone
    email
    link
  }`;

const bannerItem = ` {
    title
    type
    description
    textPosition
    image {
      url
    }
    vehicle {
      id
      title
      slug
    }
    button {
      text
      relativeUrl
      absoluteUrl
      vehicle {
        id
        title
        slug
      }
    }
  }`;
