export default class Config {
  static readonly MAPBOX_ACCESS_TOKEN =
    'pk.eyJ1Ijoic2hhcmFmdWRoZWVuIiwiYSI6ImNrNHNsMGpjYTA1dDAzZG93a2tnNzV3cnEifQ.hZ0R4NowNN6YzbhOCZFGQg';
  static GOOGLE_ANALYTICS_ID = 'UA-155985479-1';
  static franchise = 'lincoln';
  static FACEBOOK_APP_ID = '2716432038670544';
  static WHATSAPP_NO = '9651888080';
  static GOOGLE_CLIENT_ID =
    '769264614697-mpesjhovibljn2gg9el7bsp1thmr8pgk.apps.googleusercontent.com';
}
