import { post } from 'services/http';

import {
  IAboutUsPageResponse,
  IContactUs,
  ILandingScreenData,
  INewsItem,
  IOfferPageResponse,
  IProductDetails,
  IRichTextContent,
} from './cms-store';
import {
  getAllVehiclesQuery,
  getServiceScreenQuery,
  getNewsDetailsQuery,
  getNewsQuery,
  getLandingScreenQuery,
  getContactUsScreenQuery,
  getAboutUSScreenQuery,
  getOfferScreenQuery,
  getFooterQuery,
  getDisclaimerQuery,
  getTermsQuery,
  getPrivacyQuery,
} from './cmsUtils';

const cmsDomain = process.env.REACT_APP_CMS_DOMAIN;
export async function loadAllVehicles(): Promise<IProductDetails[]> {
  const query = getAllVehiclesQuery();
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Vehicles',
    query,
  });
  return (response?.data?.data as any)?.vehicles;
}

export async function loadServicePage(
  language: string
): Promise<IProductDetails[]> {
  const query = getServiceScreenQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Ownerships',
    query,
  });
  return (response?.data?.data as any)?.ownerships?.[0];
}

export async function loadNews(slug: string, locale: string): Promise<any> {
  const query = getNewsDetailsQuery(locale, slug);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'NewsItems',
    query,
  });
  return (response?.data?.data as any)?.newsItems?.[0];
}

export async function loadNewsItems(
  language: string,
  count?: number
): Promise<INewsItem[]> {
  const query = getNewsQuery(language, count);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'NewsItems',
    query,
  });
  return (response?.data?.data as any)?.newsItems;
}

export async function loadLandingPage(
  language: string
): Promise<ILandingScreenData> {
  const query = getLandingScreenQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'LandingScreens',
    query,
  });
  return (response?.data?.data as any)?.landingScreens?.[0];
}

export async function loadContactUsPage(language: string): Promise<IContactUs> {
  const query = getContactUsScreenQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Contactuses',
    query,
  });
  return (response?.data?.data as any)?.contactuses?.[0];
}

export async function loadAboutUsPage(
  language: string
): Promise<IAboutUsPageResponse> {
  const query = getAboutUSScreenQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Aboutuses',
    query,
  });
  return (response?.data?.data as any)?.aboutuses?.[0];
}

export async function loadOfferPage(
  language: string
): Promise<IOfferPageResponse> {
  const query = getOfferScreenQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Offers',
    query,
  });
  return (response?.data?.data as any)?.offers?.[0];
}

export async function loadFooterData(
  language: string
): Promise<IOfferPageResponse> {
  const query = getFooterQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Footers',
    query,
  });
  return (response?.data?.data as any)?.footers?.[0];
}
export async function loadDisclaimerData(
  language: string
): Promise<IRichTextContent> {
  const query = getDisclaimerQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Disclaimers',
    query,
  });
  return (response?.data?.data as any)?.disclaimers?.[0];
}

export async function loadTermsData(
  language: string
): Promise<IRichTextContent> {
  const query = getTermsQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Terms',
    query,
  });
  return (response?.data?.data as any)?.terms?.[0];
}

export async function loadPrivacyData(
  language: string
): Promise<IRichTextContent> {
  const query = getPrivacyQuery(language);
  const url = `${cmsDomain}graphql`;
  const response = await post(url, {
    operationName: 'Privacies',
    query,
  });
  return (response?.data?.data as any)?.privacies?.[0];
}
